<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-toolbar-title class="headline font-weight-bold">
                <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
                <router-link
                    class="text-decoration--none"
                    :to="{ name: 'roles' }"
                    >Roles</router-link
                >
            </v-toolbar-title>
        </v-app-bar>

        <v-container fill-height v-if="!loading">
            <v-row class="justify-center align-center">
                <v-col cols="12" sm="6">
                    <v-form ref="form" v-if="form">
                        <label class="text-field-label">Name</label>
                        <v-text-field
                            flat
                            outlined
                            solo
                            required
                            class="mt-2"
                            v-model="form.name"
                            :error-messages="form.$getError('name')"
                        ></v-text-field>

                        <label class="text-field-label">Permission</label>
                        <v-select
                            :error-messages="form.$getError('permissions')"
                            v-model="form.permissions"
                            :items="permissions"
                            chips
                            multiple
                            outlined
                            flat
                            solo
                            required
                        ></v-select>
                        <div class="mt-4">
                            <v-btn
                                color="primary"
                                depressed
                                class="mr-4 px-6"
                                height="40px"
                                :loading="form.$busy"
                                @click="submitForm"
                                >{{
                                    page == 'create' ? 'Create' : 'Update'
                                }}</v-btn
                            >

                            <v-btn
                                v-if="page === 'edit'"
                                color="red"
                                dark
                                depressed
                                class="mr-4 px-6"
                                height="40px"
                                @click="deleteDialog = true"
                                :loading="form.$busy"
                                >Delete</v-btn
                            >
                        </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="deleteDialog" max-width="290">
            <v-card>
                <v-card-title class="headline">Delete ?</v-card-title>

                <v-card-text
                    >Are you sure you want to delete this account ?</v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="deleteDialog = false"
                        :loading="form.$busy"
                        >Cancel</v-btn
                    >

                    <v-btn
                        color="red"
                        text
                        @click="deleteRole"
                        :loading="form.$busy"
                        >Yes, Delete please</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay absolute :value="loading" opacity="0.2">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
            {{ snackbar.message }}
            <v-btn text @click="snackbar.show = false">OK</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'
import { mapActions, mapState } from 'vuex'
import { mdiChevronLeft } from '@mdi/js'
export default {
    name: 'AdminForm',

    components: {
        AppBarNavIcon
    },
    data() {
        return {
            loading: false,
            page: 'create',
            form: new Form({}),
            permissions: [],
            icons: {
                arrowLeft: mdiChevronLeft
            },
            deleteDialog: false,
            snackbar: {
                show: false,
                message: null,
                color: ''
            },
            image: null,
            uploading: false
        }
    },
    methods: {
        submitForm() {
            this.form.$busy = true
            let action = null
            let data = null
            let success = null
            if (this.page === 'create') {
                action = 'createRole'
                data = this.form
                success = () => {
                    this.$router.replace('/roles')
                }
            } else {
                action = 'updateRole'
                data = this.form.$data()
                success = () => {
                    this.showSnackbar('Role successfully updated', 'success')
                }
            }

            this[action](data)
                .then(res => {
                    success()
                    this.form.$busy = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    } else {
                        this.showSnackbar(err.response.data.message, 'error')
                    }
                    this.form.$busy = false
                })
        },

        ...mapActions({
            getPermissions: 'permission/getPermissions',
            createRole: 'role/createRole',
            getRoleDetails: 'role/getRoleDetails',
            updateRole: 'role/updateRole'
        }),

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        },

        deleteRole() {
            this.form.$busy = true
            this.role
                .delete()
                .then(() => {
                    this.form.$busy = false
                    this.deleteDialog = false
                    this.$router.replace('/roles')
                })
                .catch(err => {
                    this.deleteDialog = false
                    this.showSnackbar(err.response.data.message, 'error')
                    this.form.$busy = false
                })
        }
    },
    computed: {
        ...mapState({
            role: state => state.role.roleDetails
        })
    },
    watch: {
        role(newValue, oldValue) {
            this.form = new Form(newValue)
        }
    },
    created() {
        if (this.$route.params.id) {
            this.page = 'edit'
            this.loading = true
            this.getRoleDetails(this.$route.params.id)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        this.showSnackbar(err.response.data.message, 'error')
                    }
                    this.form.$busy = false
                })
        }

        this.getPermissions().then(res => {
            this.permissions = res.data
        })
    }
}
</script>
