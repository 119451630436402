<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-bold"
                >Roles</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-row v-if="showSearch" align="center" style="max-width: 300px">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search name, email or phone number"
                    v-model="filter.search"
                    @keyup.enter="search"
                ></v-text-field>
            </v-row>

            <v-skeleton-loader
                type="actions"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <template v-else>
                <v-btn class="ml-5" icon @click="showSearch = !showSearch">
                    <img src="@/assets/icons/search.svg" alt height="17.7px" />
                </v-btn>
            </template>
        </v-app-bar>

        <div
            class="mt-12 mx-4"
            id="admin-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
        >
            <v-col class="text-right">
                <v-btn
                    class="mx-2"
                    right
                    fab
                    dark
                    color="primary"
                    to="/roles/create"
                >
                    <v-icon dark> {{ icons.plus }} </v-icon>
                </v-btn>
            </v-col>
            <v-simple-table>
                <thead>
                    <tr>
                        <th width="25%" class="text-left pa-0">Name</th>
                        <th width="25%" class="text-left">Added date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="2" v-if="unAuthorizedMessage != ''">
                            <div class="display-1 my-6">
                                {{ unAuthorizedMessage }}
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="role in roles"
                        :key="role.id"
                        class="clickable"
                        @click="
                            $router.push({
                                name: 'role.details',
                                params: { id: role.id }
                            })
                        "
                    >
                        <td class="py-5">{{ role.name }}</td>
                        <td class="py-5">{{ role.addedDate }}</td>
                    </tr>

                    <template v-if="loading">
                        <tr v-for="index in 10" :key="index + '-skeleton'">
                            <td class="py-3 pl-0" width="5%">
                                <v-skeleton-loader
                                    class="avatar-loading--36"
                                    type="avatar"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapMutations, mapState } from 'vuex'

import {
    mdiDotsVertical,
    mdiUnfoldMoreHorizontal,
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiChevronRight,
    mdiFileExcel,
    mdiPlus
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'

export default {
    directives: {
        infiniteScroll
    },
    mixins: [ControlsMixin],
    components: {
        AppBarNavIcon
    },
    data() {
        return {
            unAuthorizedMessage: '',
            loading: false,
            showSearch: false,
            filter: {
                search: ''
            },
            icons: {
                settings: mdiDotsVertical,
                sort: mdiUnfoldMoreHorizontal,
                search: mdiMagnify,
                view: mdiAccountCircleOutline,
                arrow: mdiChevronRight,
                export: mdiFileExcel,
                plus: mdiPlus
            }
        }
    },
    methods: {
        ...mapMutations({
            clearRoleList: 'role/clearRoleList'
        }),
        ...mapActions({
            getRoles: 'role/getRoles'
        }),
        search: debounce(function() {
            this.clearRoleList()
            this.fetchRoles()
        }, 600),

        fetchRoles(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            this.getRoles(params)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 403) {
                        this.unAuthorizedMessage = err.response.data.message
                    }
                })
        },

        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchRoles(this.listMeta.current_page + 1)
            }
        }
    },
    computed: {
        ...mapState({
            roles: state => state.role.list,
            listMeta: state => state.role.listMeta
        })
    },
    created() {
        this.fetchRoles(1)
        this.clearRoleList()
    }
}
</script>
