<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-bold"
                >Admins ({{ admins.length }})</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-row align="center" style="max-width: 300px">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search name, email or phone number"
                    v-model="filter.search"
                    @keyup.enter="search"
                ></v-text-field>
            </v-row>

            <v-skeleton-loader
                type="actions"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <template v-else>
                <v-btn class="ml-5" icon @click="search">
                    <img src="@/assets/icons/search.svg" alt height="17.7px" />
                </v-btn>
            </template>
        </v-app-bar>

        <div
            class="mt-12 mx-4"
            id="admin-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
        >
            <v-col class="text-right">
                <v-btn
                    class="mx-2"
                    right
                    fab
                    dark
                    color="primary"
                    to="/admins/create"
                >
                    <v-icon dark> {{ icons.plus }} </v-icon>
                </v-btn>
            </v-col>
            <v-simple-table>
                <thead>
                    <tr>
                        <th width="25%" colspan="2" class="text-left pa-0">
                            Name
                        </th>
                        <th width="25%" class="text-left">Email</th>
                        <th width="25%" class="text-left">Role</th>
                        <th width="15%" class="text-left">Added date</th>
                        <th width="15%" class="text-middle color-red">
                            Status
                        </th>
                        <th style="min-width: 110px;" class="text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="4" v-if="unAuthorizedMessage != ''">
                            <div class="display-1 my-6">
                                {{ unAuthorizedMessage }}
                            </div>
                        </td>
                    </tr>
                    <tr v-for="admin in admins" :key="admin.id">
                        <td colspan="2" class="py-5">
                            {{ admin.full_name }}
                        </td>
                        <td class="py-5">{{ admin.email }}</td>
                        <td class="py-5">
                            {{ admin.roles }}
                        </td>
                        <td class="py-5">{{ admin.addedDate }}</td>
                        <td
                            class="mb-0"
                            :class="{
                                'red--text': admin.is_blocked,
                                'green--text': !admin.is_blocked
                            }"
                        >
                            {{ admin.is_blocked ? 'Blocked' : 'Active' }}
                        </td>
                        <td class="py-5" justify="space-around">
                            <template>
                                <v-menu>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>{{
                                                icons.settings
                                            }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-on:click="
                                                $router.push({
                                                    name: 'admin.details',
                                                    params: { id: admin.id }
                                                })
                                            "
                                        >
                                            <v-list-item-icon>
                                                <v-icon
                                                    v-text="icons.view"
                                                ></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                                >View</v-list-item-title
                                            >
                                        </v-list-item>
                                        <v-list-item
                                            @click="
                                                showModal = true
                                                selectedAdmin = admin
                                            "
                                        >
                                            <v-list-item-icon>
                                                <v-icon
                                                    v-text="icons.unlock"
                                                ></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                                >Block</v-list-item-title
                                            >
                                        </v-list-item>
                                        <v-list-item
                                            @click="
                                                resetPasswordModal = true
                                                selectedAdmin = admin
                                            "
                                        >
                                            <v-list-item-icon>
                                                <v-icon
                                                    v-text="icons.reset"
                                                ></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title
                                                >Reset
                                                Password</v-list-item-title
                                            >
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </td>
                    </tr>

                    <template v-if="loading">
                        <tr v-for="index in 10" :key="index + '-skeleton'">
                            <td class="py-3 pl-0" width="5%">
                                <v-skeleton-loader
                                    class="avatar-loading--36"
                                    type="avatar"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </div>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
        </v-snackbar>
        <ConfirmModal
            v-model="showModal"
            title="Disable Account"
            :message="disableMessage"
            @cancel="showModal = false"
            @confirm="blockAccount"
        ></ConfirmModal>
        <ConfirmModal
            v-model="resetPasswordModal"
            title="Reset to Default Password"
            :message="resetPasswordMessage"
            @cancel="resetPasswordModal = false"
            @confirm="resetPassword"
        ></ConfirmModal>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
    mdiDotsVertical,
    mdiUnfoldMoreHorizontal,
    mdiMagnify,
    mdiPencil,
    mdiDelete,
    mdiEye,
    mdiChevronRight,
    mdiFileExcel,
    mdiPlus,
    mdiLock,
    mdiLockOpen,
    mdiLockReset
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
    name: 'AdminsPage',

    mixins: [ControlsMixin],

    directives: {
        infiniteScroll
    },

    components: {
        AppBarNavIcon,
        ConfirmModal
    },

    data() {
        return {
            unAuthorizedMessage: '',
            loading: false,
            showSearch: false,
            showProfile: false,
            selectedProfile: null,
            showModal: false,
            resetPasswordModal: false,
            selectedAdmin: [
                {
                    is_blocked: false
                }
            ],
            filter: {
                search: ''
            },
            icons: {
                settings: mdiDotsVertical,
                sort: mdiUnfoldMoreHorizontal,
                search: mdiMagnify,
                edit: mdiPencil,
                delete: mdiDelete,
                view: mdiEye,
                arrow: mdiChevronRight,
                export: mdiFileExcel,
                plus: mdiPlus,
                lock: mdiLock,
                unlock: mdiLockOpen,
                reset: mdiLockReset
            },
            snackbar: {
                show: false,
                message: null,
                color: null
            }
        }
    },

    created() {
        this.clearAdmin()
        this.fetchAdmins(1)
    },

    computed: {
        ...mapState({
            admins: state => state.admin.list,
            listMeta: state => state.admin.listMeta
        }),
        disableMessage() {
            const action = this.selectedAdmin.is_blocked
                ? 'unblocked'
                : 'blocked'
            return `Are you sure you want ${action} this account?`
        },

        resetPasswordMessage() {
            return `Are you sure you want to reset this users account to default password?`
        },

        paginationLength() {
            return (this.listMeta.total / this.listMeta.per_page) | 0
        }
    },

    methods: {
        ...mapActions({
            getAdmins: 'admin/getAdmins',
            exportToExcel: 'admin/exportToExcel',
            blockUser: 'admin/blockUser',
            resetDefault: 'admin/resetPassword'
        }),

        ...mapMutations({
            clearAdmin: 'admin/clearAdminList'
        }),

        pageChanged(page) {
            this.fetchAdmins(page)
        },

        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchAdmins(this.listMeta.current_page + 1)
            }
        },

        search: debounce(function() {
            this.clearAdmin()
            this.fetchAdmins()
        }, 600),

        async fetchAdmins(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            this.getAdmins(params)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 403) {
                        this.unAuthorizedMessage = err.response.data.message
                    }
                })
        },

        showProfileDialog(profile) {
            this.selectedProfile = profile
            this.showProfile = true
        },

        async exportList() {
            this.loading = true
            await this.exportToExcel()
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        })
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'users_list.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    this.showSnackbar('File successfully downloaded', 'success')
                })
                .catch(() => {
                    this.showSnackbar('Ops! Something went wrong', 'red')
                })
            this.loading = false
        },

        resetPassword(value) {
            this.resetDefault({
                id: this.selectedAdmin.id
            }).then(() => {
                this.resetPasswordModal = false
                this.showSnackbar(
                    `Admin user password successfully set to Default`,
                    'success'
                )
            })
        },

        blockAccount(value) {
            const action = this.selectedAdmin.is_blocked
                ? 'unblocked'
                : 'blocked'
            this.blockUser({
                id: this.selectedAdmin.id,
                action
            })
                .then(() => {
                    this.showModal = false
                    this.clearAdmin()
                    this.fetchAdmins(1)
                    this.showSnackbar(
                        `Admin successfully ${action}!`,
                        'success'
                    )
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    }
                })
        },

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.color = color
            this.snackbar.show = true
        }
    }
}
</script>
