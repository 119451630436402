<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-toolbar-title class="headline font-weight-bold">
                <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
                <router-link
                    class="text-decoration--none"
                    :to="{ name: 'admins' }"
                    >Administrators</router-link
                >
            </v-toolbar-title>
        </v-app-bar>

        <v-container fill-height v-if="!loading">
            <v-row class="justify-center align-center">
                <v-col cols="12" sm="6">
                    <div
                        class="display-2 font-weight-bold my-6 text-capitalize"
                    >
                        {{ formTitle }}
                    </div>
                    <v-form ref="form" v-if="form">
                        <label class="text-field-label">Name</label>
                        <v-text-field
                            flat
                            outlined
                            solo
                            required
                            class="mt-2 capitalize"
                            v-model="form.full_name"
                            :error-messages="form.$getError('full_name')"
                        ></v-text-field>

                        <label class="text-field-label">Email</label>
                        <v-text-field
                            flat
                            outlined
                            solo
                            required
                            class="mt-2"
                            v-model="form.email"
                            :error-messages="form.$getError('email')"
                        ></v-text-field>

                        <label class="text-field-label">Role</label>
                        <v-select
                            v-model="form.roles"
                            :items="_roles.concat(roles)"
                            chips
                            multiple
                            solo
                            :error-messages="form.$getError('roles')"
                            outlined
                            flat
                        ></v-select>

                        <label class="text-field-label"
                            >Status:
                            <span
                                :class="{
                                    'red--text': admin.is_blocked,
                                    'green--text': !admin.is_blocked
                                }"
                                >{{
                                    form.is_blocked ? 'Blocked' : 'Active'
                                }}</span
                            ></label
                        >
                        <br />
                        <div class="mt-4">
                            <v-btn
                                color="primary"
                                depressed
                                class="mr-4 px-6"
                                height="40px"
                                :loading="form.$busy"
                                @click="submitForm"
                                >{{
                                    page == 'create' ? 'Create' : 'Update'
                                }}</v-btn
                            >

                            <v-btn
                                v-if="page === 'edit'"
                                color="red"
                                dark
                                depressed
                                class="mr-4 px-6"
                                height="40px"
                                @click="deleteDialog = true"
                                :loading="form.$busy"
                                >Delete</v-btn
                            >
                        </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="deleteDialog" max-width="290">
            <v-card>
                <v-card-title class="headline">Delete ?</v-card-title>

                <v-card-text
                    >Are you sure you want to delete this account ?</v-card-text
                >

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="deleteDialog = false"
                        :loading="form.$busy"
                        >Cancel</v-btn
                    >

                    <v-btn
                        color="red"
                        text
                        @click="deleteAdmin"
                        :loading="form.$busy"
                        >Yes, Delete please</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay absolute :value="loading" opacity="0.2">
            <v-progress-circular
                indeterminate
                color="primary"
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
            {{ snackbar.message }}
            <v-btn text @click="snackbar.show = false">OK</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Form from '@/utils/form'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiChevronLeft, mdiEye, mdiEyeOff } from '@mdi/js'

export default {
    name: 'AdminForm',
    components: {
        AppBarNavIcon
    },
    data() {
        return {
            page: 'create',
            loading: false,
            form: new Form({}),
            icons: {
                arrowLeft: mdiChevronLeft,
                eyeOn: mdiEye,
                eyeOff: mdiEyeOff
            },
            deleteDialog: false,
            snackbar: {
                show: false,
                message: null,
                color: ''
            },
            uploading: false,
            formTitle: '',
            roles: []
        }
    },
    methods: {
        submitForm() {
            this.form.$busy = true
            let action = null
            let data = this.form
            let success = null
            if (this.page === 'create') {
                action = 'createAdmin'
                success = () => {
                    this.$router.replace('/admins')
                }
            } else {
                action = 'updateAdmin'
                success = () => {
                    this.showSnackbar(
                        'Admin user successfully updated',
                        'success'
                    )
                }
            }

            this[action](data)
                .then(res => {
                    success()
                    this.form.$busy = false
                })
                .catch(err => {
                    this.showSnackbar(err.response.data.message, 'error')
                    this.form.$busy = false
                })
        },

        ...mapActions({
            getAdminDetails: 'admin/getAdminDetails',
            updateAdmin: 'admin/updateAdmin',
            createAdmin: 'admin/createAdmin',
            getRoles: 'role/getRoles'
        }),
        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        },

        deleteAdmin() {
            this.form.$busy = true
            this.admin.delete().then(() => {
                this.form.$busy = false
                this.deleteDialog = false
                this.showSnackbar('Admin deleted successfully!', 'success')
                this.$router.replace('/admins')
            })
        },

        ...mapMutations({
            setAdminDetails: 'admin/setAdminDetails',
            setRoleList: 'role/setRoleList'
        })
    },
    computed: {
        ...mapState({
            admin: state => state.admin.adminDetails,
            auth: state => state.auth.user,
            _roles: state => {
                let data = state.role.list
                let _roles = []
                for (var i in data) {
                    _roles.push(data[i].name)
                }
                return _roles
            }
        })
    },
    watch: {
        admin(newValue, oldValue) {
            this.form = new Form(newValue)
            if (newValue.id === this.auth.id) {
                this.formTitle = 'My profile'
            } else {
                if (this.page === 'edit') {
                    this.formTitle = newValue.full_name
                }
            }
            for (var i in newValue.roles) {
                if (!this.roles.includes(newValue.roles[i])) {
                    this.roles.push(newValue.roles[i])
                }
            }
        }
    },
    created() {
        if (this.$route.params.id) {
            this.page = 'edit'
            this.loading = true
            this.getAdminDetails(this.$route.params.id).then(res => {
                this.loading = false
            })
        } else {
            this.setAdminDetails({ avatar: null })
        }

        this.getRoles({})
    }
}
</script>
